'use client';

import experiments from '@/utils/labs/experiments';
import { useEffect } from 'react';
import Script from 'next/script';

type TGptScriptProps = {
  gptUrl: string;
  labsConfig: TLabsConfig;
  pwtPubId?: string;
  pwtProfId?: string;
};

const GptScript = ({ gptUrl, labsConfig, pwtPubId, pwtProfId }: TGptScriptProps) => {
  const { isPubmaticWrapperEnabled, isPubmaticJSEnabled } = experiments({
    labsConfig,
  });

  const PUBMATIC_BASE_URL = '//ads.pubmatic.com/AdServer/js/pwt';

  const getPubmaticUrl = (): string | null => {
    if (isPubmaticWrapperEnabled && pwtPubId && pwtProfId) {
      return `${PUBMATIC_BASE_URL}/${pwtPubId}/${pwtProfId}/pwt.js`;
    }
    if (isPubmaticJSEnabled && pwtPubId) {
      return `${PUBMATIC_BASE_URL}/${pwtPubId}/14621/pwt.js`;
    }
    return null;
  };

  // Initialize googletag
  useEffect(() => {
    window.googletag = window.googletag || {};
    window.googletag.cmd = window.googletag.cmd || [];
  }, []);

  const pubmaticUrl = getPubmaticUrl();

  return (
    <>
      {pubmaticUrl && <Script src={pubmaticUrl} strategy="beforeInteractive" />}
      <Script src={gptUrl} strategy="afterInteractive" />
    </>
  );
};

export default GptScript;
