import React from 'react';
import Script from 'next/script';

interface AwsWAFScriptProps {
  srcUrl?: string;
}

const AwsWAFScript = ({ srcUrl }: AwsWAFScriptProps) => {
  if (!srcUrl) {
    return null;
  }
  return <Script src={srcUrl} />;
};
export default AwsWAFScript;
